<template>
  <div class="welcome container-fluid pt-5">
    <div class="row welcome-row justify-content-center">
      <div
        class="
          col-md-5
          h-auto
          welcome-col
          d-flex
          justify-content-center
          align-items-center
        "
      >
        <article class="text-center text-md-left">
          <h1 class="title font-weight-bold">
            Administra tu negocio <br class="d-none d-md-block" />
            con la mejor solución digital
          </h1>

          <img
            src="@/assets/images/welcome.png"
            alt="Bienvenido al panel de administración de Softaki"
            class="image mt-4"
          />

          <p class="text-muted mt-md-5">
            Creado por
            <a
              href="https://www.softaki.com/"
              target="_blank"
              class="font-weight-bold"
              >Softaki</a
            >
          </p>
        </article>
      </div>

      <div class="col-md-4">
        <div class="card shadow-card mt-md-5 py-4 px-3">
          <div class="card-body">
            <h2 class="subtitle text-center">
              Ingresar a panel de administración
            </h2>

            <b-form
              @submit.prevent="onLogin"
              class="mt-5"
              v-if="!formRecoverPassword"
            >
              <b-form-group
                id="email"
                label="Correo electrónico"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="login.email"
                  type="email"
                  placeholder="Ingresar correo electrónico"
                ></b-form-input>

                <FormError
                  text="El correo es requerido"
                  :show="$v.login.email.$error && !$v.login.email.required"
                />
              </b-form-group>

              <b-form-group
                id="password"
                label="Contraseña"
                label-for="password"
              >
                <b-form-input
                  id="password"
                  v-model="login.password"
                  type="password"
                  placeholder="Ingresar contraseña"
                ></b-form-input>

                <FormError
                  text="La contraseña es requerida"
                  :show="
                    $v.login.password.$error && !$v.login.password.required
                  "
                />
              </b-form-group>

              <b-alert
                show
                variant="danger"
                class="small text-center px-2"
                v-if="loginError"
                >{{ loginError }}</b-alert
              >

              <div
                class="
                  form-group
                  d-flex
                  justify-content-between
                  align-items-center
                  mt-4
                "
              >
                <a
                  href=""
                  class="text-info"
                  @click.prevent="formRecoverPassword = true"
                  >Olvidé mi contraseña</a
                >

                <input
                  type="submit"
                  class="btn btn-blue px-4"
                  :disabled="loading"
                  value="Ingresar"
                />
              </div>
            </b-form>

            <b-form @submit.prevent="sendEmail()" class="mt-5" v-else>
              <p>
                Le enviaremos un correo con las instrucciones para recuperar el
                acceso a su cuenta.
              </p>

              <b-form-group
                id="email"
                label="Correo electrónico"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="recoverPassword.email"
                  type="email"
                  placeholder="Ingresar correo electrónico"
                ></b-form-input>

                <FormError
                  text="El correo es requerido"
                  :show="
                    $v.recoverPassword.email.$error &&
                    !$v.recoverPassword.email.required
                  "
                />
              </b-form-group>

              <div
                class="
                  form-group
                  d-flex
                  justify-content-between
                  align-items-center
                  mt-4
                "
              >
                <a
                  href=""
                  class="text-info"
                  @click.prevent="formRecoverPassword = false"
                  >Ingresar</a
                >

                <button
                  type="submit"
                  :disabled="loadingRecoverPassword"
                  class="btn btn-success px-4"
                >
                  Recuperar acceso
                  <i class="fas fa-paper-plane"></i>
                </button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { token_name } from '@/utils/static-values'

import { login as loginMutation } from "./graphql/mutations/login.gql";
import RecuperarContraUsuario from "./graphql/mutations/RecuperarContraUsuario.gql";

import { required } from "vuelidate/lib/validators";

import { getLoginError } from "@/utils/static-values.js";

import { FormError } from "@/components";

export default {
  data() {
    return {
      login: {
        email: null,
        password: null,
      },
      loading: false,
      loginError: null,
      formRecoverPassword: false,
      recoverPassword: {
        email: null,
      },
      loadingRecoverPassword: false,
    };
  },
  components: {
    FormError,
  },
  validations: {
    login: {
      email: {
        required,
      },
      password: {
        required,
      },
    },
    recoverPassword: {
      email: {
        required,
      },
    },
  },
  methods: {
    async onLogin() {
      this.loginError = null;

      this.$v.login.$touch();
      if (this.$v.login.$error) return;

      this.loading = true;

      let response = await this.$apollo.mutate({
        mutation: loginMutation,
        errorPolicy: "all",
        variables: {
          input: {
            email: this.login.email,
            password: this.login.password,
            tipoInicio: 1, // Correo y contraseña
          },
        },
      });

      if (response.data.login) {
        let data = response.data.login;

        this.$cookies.set(token_name(), data);

        this.$store.commit("auth/SET_USER", data);

        this.$router.push("/");
      } else if (response.errors.length) {
        this.loginError = getLoginError(response.errors[0].debugMessage).text;
      }

      this.loading = false;
    },
    async sendEmail() {
      this.$v.recoverPassword.$touch();
      if (this.$v.recoverPassword.$error) return;

      try {
        this.loadingRecoverPassword = true;

        await this.$apollo.mutate({
          mutation: RecuperarContraUsuario,
          variables: {
            input: {
              email: this.recoverPassword.email,
            },
          },
        });

        this.$toast.success("Slide eliminado");

        this.loadingRecoverPassword = false;
      } catch (error) {
        this.$toast.error("Ocurrió un error, por favor inténtelo nuevamente");
        this.loading = false
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables";

.welcome {
  height: 100vh;

  .welcome-row {
    @media (min-width: 768px) {
      height: 100%;
    }
  }

  .welcome-col {
    @media (min-width: 768px) {
      height: 100%;
    }
  }

  .title {
    font-size: 1.5em;
    color: rgba($dark, 0.9);

    @media (min-width: 768px) {
      font-size: 1.7em;
      max-width: 90%;
    }
  }

  .subtitle {
    font-size: 1.1em;
  }

  .image {
    max-width: 95%;
    margin: 0 auto;

    @media (min-width: 768px) {
      margin-left: -4rem;
    }
  }
}
</style>